.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.form-submit-button {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  z-index: 34;
  right: 0px;
  height: 56px;
  padding: 8px 20px;
  background-color: white;
  box-shadow: 0 -2px 5px 0px rgba(126, 126, 126, 0.4);
}

.form-submit-button .confirm-request {
  position: absolute;
  left: 20px;
}

.App-link {
  color: rgb(112, 76, 182);
}

.suffix-hide .ant-input-suffix {
  display: none;
}
.wrapper-select .ant-select-selector{
  max-height: 100px;
  overflow-y: scroll;
}
@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.swal2-popup {
  padding: 1.25em !important;
}

/* hidden filter card start */

.hidden-filter-card {
  border: none;
}

.hidden-filter-card > .ant-card-head {
  display: none;
}

.hidden-filter-card > .ant-card-body {
  padding: 0;
}

/* hidden filter card end */

.fixed-action-bar {
  height: 56px;
  position: fixed;
  bottom: 0;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background-color: white;
  box-shadow: 0 -2px 5px 0px rgba(126, 126, 126, 0.4);
  z-index: 34;
}


/* 4 row text */
 .block-ellipsis {
  display: block;
  display: -webkit-box;
  line-height: 20px;
  -webkit-line-clamp: 4;  /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
 }