:root {
  --primary-color: #f5b336;
  --black-color: #000;
  --white-color: #fff;
}
#root {
  height: 100%;
}

body {
  color: var(--black-color);
  font-family: 'IBM Plex Sans', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.anticon svg {
  vertical-align: baseline;
}

.img-upload {
  object-fit: contain;
  height: 50px;
}

.barcode-detail-form-desc .ant-descriptions-item-label {
  width: 40%;
}

body .me-0 {
  margin-right: 0 !important;
}

body {
  overflow-x: hidden;
}

.upload-hidden-delete span.ant-upload-list-item-card-actions {
  display: none;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 16px;
  border: 2px solid #fff;
  max-height: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #d8d8d8;
  border: 2px solid #f4f4f4;
}
